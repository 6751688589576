import {v4} from 'uuid';

import {
    createListAction,
    createListItemAction,
    deleteListItemAction,
    deleteListAction,
    updateListAction,
    updateListItemAction,
    updateListItemNoResponseAction,
    updateMultipleListItemsAction,
    updatePrimaryListAction,
    createMultipleListItemsAction,
    deleteListAllItemsAction,
    submitListInvitationAction,
    removeListCollaboratorAction,
    confirmListInvitationAction,
    leaveListAction,
    deleteMultipleListItemsAction,
    createListActionV2,
    createListItemActionV2,
    updatePrimaryListActionV2
} from 'client/graphql/mutations/mutation-actions/list-mutation-actions';
import {getErrorResponseMessage} from 'client/utils/graphql-helpers';
import {getLists_lists, getLists_lists_listItems} from 'autogen/getLists';
import {createListItemInput} from 'autogen/globalTypes';
import {getListAndListItemsWithDepartmentByListId_list_listItems} from 'autogen/getListAndListItemsWithDepartmentByListId';

const currentTimeInUTC = () => new Date().toISOString();

const getDefaultNewListItem = () => ({
    created: currentTimeInUTC(),
    isChecked: false,
    isRemoved: false,
    isWeighted: null,
    itemId: null,
    lastModified: currentTimeInUTC(),
    listItemId: v4(),
    productId: null,
    quantity: 1,
    upc: null
});

const logError = (error) => {
    const message = getErrorResponseMessage(error);

    console.error(message, {error});
};

interface ICreateListOptions {
    name: string;
    description?: string;
}

export interface IUpdateListItem {
    isChecked: boolean;
    lastModified: string;
    listItemId: string;
}

export const createList = async (
    {name, description}: ICreateListOptions,
    setErrorMessage: (message: string) => void
) => {
    let response;

    try {
        response = await createListAction({
            created: currentTimeInUTC(),
            description: description || null,
            isPrimary: false,
            isRemoved: false,
            lastModified: currentTimeInUTC(),
            listId: v4(),
            name
        });

        setErrorMessage('');
    } catch (error) {
        setErrorMessage(getErrorResponseMessage(error));
    }

    return response;
};

export const createListV2 = async (
    {name, description}: ICreateListOptions,
    setErrorMessage: (message: string) => void
) => {
    let response;

    try {
        response = await createListActionV2({
            created: currentTimeInUTC(),
            description: description || null,
            isPrimary: false,
            isRemoved: false,
            lastModified: currentTimeInUTC(),
            listId: v4(),
            name
        });

        setErrorMessage('');
    } catch (error) {
        setErrorMessage(getErrorResponseMessage(error));
    }

    return response;
};

export const updateList = async (updatedList, setErrorMessage) => {
    let response;

    try {
        response = await updateListAction({
            description: updatedList.description,
            lastModified: currentTimeInUTC(),
            listId: updatedList.listId,
            name: updatedList.name
        });

        setErrorMessage('');
    } catch (error) {
        setErrorMessage(getErrorResponseMessage(error));
    }

    return response;
};

export const deleteList = async (listId, isV2?: boolean) => {
    if (isV2) {
        let response;

        try {
            response = await deleteListAction(listId);
        } catch (error) {
            logError(error);
        }

        return response;
    }

    try {
        await deleteListAction(listId);
    } catch (error) {
        logError(error);
    }
};

export const togglePrimaryList = async (lists, newPrimaryListId) => {
    const newPrimaryList = lists.find((list) => list.listId === newPrimaryListId);

    try {
        await updatePrimaryListAction({
            created: newPrimaryList.created,
            isPrimary: true,
            isRemoved: newPrimaryList.isRemoved,
            lastModified: currentTimeInUTC(),
            listId: newPrimaryList.listId,
            name: newPrimaryList.name
        });
    } catch (error) {
        logError(error);
    }
};

export const togglePrimaryListV2 = async (listId, listName) => {
    try {
        return updatePrimaryListActionV2({
            isPrimary: true,
            lastModified: currentTimeInUTC(),
            listId,
            name: listName
        });
    } catch (error) {
        logError(error);
    }
};

export const createListItem = async (
    currentListItems: getLists_lists_listItems[] | null,
    {dealId, itemId, listId, productId, text, upc}: Partial<createListItemInput>
) => {
    const defaultReversedOrderIndex = 1;
    const getNextReversedOrderIndex = (listItems: getLists_lists_listItems[] | null) =>
        listItems
            ?.map((item) => item.reversedOrderIndex + 1)
            .sort(
                (firstReversedOrderIndex, secondReversedOrderIndex) =>
                    secondReversedOrderIndex - firstReversedOrderIndex
            )[0];

    try {
        await createListItemAction({
            ...getDefaultNewListItem(),
            dealId: dealId ?? null,
            itemId: itemId ?? null,
            listId,
            productId: productId ? Number(productId) : null,
            reversedOrderIndex: getNextReversedOrderIndex(currentListItems) ?? defaultReversedOrderIndex,
            text: text ?? '',
            upc: upc ? upc.toString() : null
        });
    } catch (error) {
        logError(error);
    }
};

export const createListItemV2 = async (
    currentListItems: getLists_lists_listItems[] | null,
    {dealId, itemId, listId, productId, text, upc}: Partial<createListItemInput>
) => {
    const defaultReversedOrderIndex = 1;
    const getNextReversedOrderIndex = (listItems: getLists_lists_listItems[] | null) =>
        listItems
            ?.map((item) => item.reversedOrderIndex + 1)
            .sort(
                (firstReversedOrderIndex, secondReversedOrderIndex) =>
                    secondReversedOrderIndex - firstReversedOrderIndex
            )[0];

    try {
        return createListItemActionV2({
            ...getDefaultNewListItem(),
            dealId: dealId ?? null,
            itemId: itemId ?? null,
            listId,
            productId: productId ? Number(productId) : null,
            reversedOrderIndex: getNextReversedOrderIndex(currentListItems) ?? defaultReversedOrderIndex,
            text: text ?? '',
            upc: upc ? upc.toString() : null
        });
    } catch (error) {
        logError(error);
    }
};

export const createMultipleListItems = async (currentListItems, listId, listItemValues) => {
    const reversedOrderIndex =
        currentListItems.reduce((maxOrderIndex, item) => Math.max(maxOrderIndex, item.reversedOrderIndex), 0) + 1;

    try {
        const listItemsInput = listItemValues
            .filter((listItem) => listItem)
            .map((text, index) => ({
                ...getDefaultNewListItem(),
                reversedOrderIndex: reversedOrderIndex + index,
                text
            }));

        await createMultipleListItemsAction(listId, listItemsInput);
    } catch (error) {
        logError(error);
    }
};

export const createQuickStartListItems = async (listId, listItemValues) => {
    try {
        await createMultipleListItemsAction(listId, listItemValues);
    } catch (error) {
        logError(error);
    }
};

export const deleteListItemByProductOrDeal = async (
    listId: string,
    lists?: getLists_lists[] | null,
    productId?: string,
    dealId?: string,
    itemId?: string
) => {
    const currentList = lists?.find((list) => list.listId === listId);
    const listItem = currentList?.listItems?.find((item) => {
        if (productId) {
            return item.productId === Number(productId);
        } else if (dealId) {
            return item.dealId === Number(dealId);
        }

        return item.itemId === itemId;
    });

    try {
        await deleteListItemAction(listItem?.listItemId);
    } catch (error) {
        logError(error);
    }
};

export const deleteListItem = async (listItemId: string) => {
    try {
        await deleteListItemAction(listItemId);
    } catch (error) {
        logError(error);
    }
};

export const deleteMultipleListItems = async (listId: string, listItemsId: string[]) => {
    try {
        await deleteMultipleListItemsAction(listId, listItemsId);
    } catch (error) {
        logError(error);
    }
};

export const updateListItem = (listItem) => {
    const updatedListItem = {...listItem};

    delete updatedListItem.__typename;

    updateListItemAction({
        ...updatedListItem,
        lastModified: currentTimeInUTC()
    });
};

export const updateListItemNoResponse = (listItem: getListAndListItemsWithDepartmentByListId_list_listItems) => {
    let response;

    try {
        updateListItemNoResponseAction({
            ...listItem,
            lastModified: currentTimeInUTC()
        });

        response = {
            data: {}
        };

        return response;
    } catch (error) {
        logError(error);

        return response;
    }
};

export const updateMultipleListItems = async (listId: string, updateListItemInput: IUpdateListItem[] | undefined) => {
    let response;

    try {
        response = await updateMultipleListItemsAction(listId, updateListItemInput);
    } catch (error) {
        logError(error);
    }

    return response;
};

export const getListImageProductId = (listItems) => {
    if (!listItems.length) {
        return undefined;
    }

    const lowestIndexItem = listItems
        .filter((listItem) => listItem.productId)
        .reduce(
            (lowItem, currentItem) =>
                lowItem.reversedOrderIndex <= currentItem.reversedOrderIndex ? lowItem : currentItem,
            0
        );

    return lowestIndexItem.productId;
};

export const deleteListAllItems = async (listId: string) => {
    let response;

    try {
        response = await deleteListAllItemsAction(listId);
    } catch (error) {
        logError(error);
    }

    return response;
};

export const removeListCollaborator = async (listId: string, customerUuid: string) => {
    let response;

    try {
        response = await removeListCollaboratorAction(listId, customerUuid);
    } catch (error) {
        logError(error);
    }

    return response;
};

export const submitListInvitation = async (listId: string, email: string) => {
    let response;

    try {
        response = await submitListInvitationAction(listId, email);
    } catch (error) {
        logError(error);
    }

    return response;
};

export const confirmListInvitation = async (token: string, status: string, list: {}) => {
    let response;

    try {
        response = await confirmListInvitationAction(token, status, list);
    } catch (error) {
        logError(error);
    }

    return response;
};

export const leaveList = async (listId: string, customerUuid: string) => {
    let response;

    try {
        response = await leaveListAction(listId, customerUuid);
    } catch (error) {
        logError(error);
    }

    return response;
};
