import ServerErrorPage from '@hy-vee/nav/lib/screens/error';
import NextError from 'next/error';
import {NextSeo} from 'next-seo';

const Error = ({hasGetInitialPropsRun, err}) => {
    if (!hasGetInitialPropsRun && err) {
        console.error('Page Error', {error: err});
    }

    return (
        <>
            <NextSeo title={'500 - Hy-Vee Aisles Online'} />
            <ServerErrorPage onRetry={() => window.location.reload()} />
        </>
    );
};

Error.getInitialProps = async ({res, err}) => {
    const errorInitialProps = await NextError.getInitialProps({
        err,
        res
    });

    errorInitialProps.hasGetInitialPropsRun = true;

    if (err) {
        console.error('Error handled in _error.js', {
            error: err
        });
    }

    return errorInitialProps;
};

export default Error;
