import {ChangeEvent, Dispatch, FC, FormEvent, PropsWithChildren, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {colors, spacing} from '@hy-vee/themes';
import {Alert, Input, Button} from '@hy-vee/web-core';
import getConfig from 'next/config';

import fetchRequest from 'client/services/fetch-service';
import {GetActivePickupLocations_pickupLocations} from 'autogen/GetActivePickupLocations';
import {
    GetCartsForReservationDetails_carts_pickupLocation,
    GetCartsForReservationDetails_carts_store
} from 'autogen/GetCartsForReservationDetails';
import {getDeliveryAddressesByCustomerId_deliveryAddresses} from 'autogen/getDeliveryAddressesByCustomerId';
import AlertStatusType from 'client/enums/alert-status-types';
import {ReservationViewType} from 'client/views/reservation-drawer';
import {BasketContinuityCartInput, FulfillmentType} from 'autogen/globalTypes';
import {getBasketContinuitySummaryDrawer} from 'client/services/basket-continuity-service';

import {BasketContinuityLoadingSpinner} from '../../components/reservation-drawer-bc-loading';

export interface ILocationOpeningSoonProps {
    basketContinuityInput: BasketContinuityCartInput;
    cartId: string | null;
    customerId: number | undefined;
    pendingPickupLocation: GetActivePickupLocations_pickupLocations | null;
    setBasketContinuityInput: Dispatch<SetStateAction<BasketContinuityCartInput | any>>;
    setPendingPickupLocation: Dispatch<SetStateAction<GetActivePickupLocations_pickupLocations | null>>;
    setSelectedPickupLocation: Dispatch<SetStateAction<GetCartsForReservationDetails_carts_pickupLocation>>;
    setDeliveryAddress: Dispatch<SetStateAction<getDeliveryAddressesByCustomerId_deliveryAddresses | null>>;
    setFulfillmentLocationId: Dispatch<SetStateAction<number | null>>;
    setFulfillmentType: Dispatch<SetStateAction<String>>;
    setStore: Dispatch<SetStateAction<GetCartsForReservationDetails_carts_store>>;
    closeDrawer: () => void;
    setActiveView: Dispatch<SetStateAction<ReservationViewType>>;
}

const LocationOpeningSoonContainer = styled.div`
    padding: 0 16px;
    display: flex;
    height: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const SecondaryText = styled.div`
    color: ${colors.grey[500]};
    margin-bottom: 1rem;
`;

const Footer = styled.div`
    position: sticky;
    bottom: 0;
    padding: 24px 0;
`;

const LocationOpeningSoon: FC<PropsWithChildren<PropsWithChildren<ILocationOpeningSoonProps>>> = ({
    basketContinuityInput,
    cartId,
    customerId,
    pendingPickupLocation,
    setBasketContinuityInput,
    setDeliveryAddress,
    closeDrawer,
    setFulfillmentLocationId,
    setFulfillmentType,
    setSelectedPickupLocation,
    setStore,
    setActiveView
}) => {
    const {publicRuntimeConfig} = getConfig();
    const BASE_URL = publicRuntimeConfig.appUri;
    const [localPasscode, setLocalPasscode] = useState('');
    const [isPasscodeIncorrect, setIsPasscodeIncorrect] = useState(false);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBCLoading, setIsBCLoading] = useState(false);

    const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (isPasscodeIncorrect) setIsPasscodeIncorrect(false);

        if (isNetworkError) setIsNetworkError(false);

        setLocalPasscode(e.target.value);
    };

    const handlePasscodeSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const payload = {
                passcode: localPasscode,
                pickupLocationId: Number(pendingPickupLocation!.pickupLocationId)
            };
            const response = await fetchRequest(`${BASE_URL}/aisles-online/api/passcode-validation`, {
                body: JSON.stringify(payload),
                method: 'POST'
            });

            const {isValid} = await response.json();

            if (isValid) {
                setFulfillmentType(pendingPickupLocation!.fulfillmentType);
                setSelectedPickupLocation(pendingPickupLocation!);
                setFulfillmentLocationId(pendingPickupLocation?.fulfillmentLocationId!);

                if (pendingPickupLocation!.fulfillmentStore) {
                    setStore(pendingPickupLocation!.fulfillmentStore);
                }

                setDeliveryAddress(null);
                setIsBCLoading(true);
                await getBasketContinuitySummaryDrawer({
                    ...basketContinuityInput,
                    cartId,
                    closeDrawer,
                    customerId,
                    deliveryAddressId: null,
                    fulfillmentLocationId: pendingPickupLocation?.fulfillmentLocationId,
                    fulfillmentType: pendingPickupLocation!.fulfillmentType,
                    pickupLocationId:
                        pendingPickupLocation?.pickupLocationId &&
                        pendingPickupLocation.fulfillmentType === FulfillmentType.PICKUP_POINT
                            ? Number(pendingPickupLocation.pickupLocationId)
                            : null,
                    setActiveView,
                    setBasketContinuityInput,
                    storeId: pendingPickupLocation?.fulfillmentStoreId
                });
            } else {
                setIsPasscodeIncorrect(true);
            }
        } catch (error) {
            console.error('Network error occured on passcode validation', {
                customerId,
                error,
                pickupLocation: Number(pendingPickupLocation!.pickupLocationId)
            });
            setIsNetworkError(true);
        }

        setIsBCLoading(false);
        setIsLoading(false);
    };

    if (isBCLoading) {
        return <BasketContinuityLoadingSpinner />;
    }

    return (
        <LocationOpeningSoonContainer>
            <Form onSubmit={async (e) => handlePasscodeSubmit(e)}>
                <div style={{flexGrow: 2}}>
                    {isNetworkError && (
                        <Alert
                            css={`
                                margin-bottom: ${spacing.medium};
                            `}
                            status={AlertStatusType.ERROR_ALERT}
                        >
                            {'Something went wrong. Please check back soon.'}
                        </Alert>
                    )}
                    <SecondaryText>
                        We&apos;re eagerly awaiting the opening of this location. Please check back for updates.
                    </SecondaryText>
                    <Input
                        autoFocus
                        error={isPasscodeIncorrect ? 'Passcode is incorrect' : null}
                        id="enter-passcode-input"
                        label="Enter passcode to proceed"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnInputChange(e)}
                        textTransform="none"
                        type="password"
                    />
                </div>
                <Footer>
                    <Button
                        block
                        disabled={Boolean(!localPasscode.length) || isLoading}
                        isLoading={isLoading}
                        type="submit"
                    >
                        Submit
                    </Button>
                </Footer>
            </Form>
        </LocationOpeningSoonContainer>
    );
};

export default LocationOpeningSoon;
