import {useEffect, useState} from 'react';

import fetch from 'client/services/fetch-service';
import {isServerSide} from 'client/lib/env';

import {useCustomerData} from '../../../../hooks/customer-hooks';
import {IFirestoreCallout} from '../types';

interface IHomescreenData {
    callout: IFirestoreCallout[];
}

interface IUseHomescreenCMS {
    data: IHomescreenData | null;
    error?: any;
    loading: boolean;
}

let homeScreenCMSData: IHomescreenData | null = null;
let setResponse, response;

const useHomeScreenCMS = (): IUseHomescreenCMS => {
    [response, setResponse] = useState<IUseHomescreenCMS>({
        data: homeScreenCMSData,
        loading: !homeScreenCMSData
    });

    const isServer = isServerSide();

    useEffect(() => {
        const setFirestoreData = async () => {
            if (!response.data) {
                setResponse({
                    data: null,
                    loading: true
                });

                try {
                    const firestoreResponse = await fetch('/aisles-online/api/firestore/web-home-screen-cms');
                    const data = await firestoreResponse.json();

                    homeScreenCMSData = data;

                    setResponse({
                        data,
                        loading: false
                    });
                } catch (error) {
                    console.error('Error loading CMS data', {
                        component: 'home-page',
                        error
                    });
                    setResponse({
                        data: null,
                        error,
                        loading: false
                    });
                }
            }
        };

        setFirestoreData();
    }, [isServer]);

    return response;
};

export const useGlobalCallout = () => {
    const customer = useCustomerData();
    const cmsResponse = useHomeScreenCMS();
    const globalCallout = cmsResponse.data?.callout?.find((callout) => callout.global);
    const isCurrentStoreCallout = globalCallout?.stores?.includes(customer.storeId);

    return {
        data: isCurrentStoreCallout ? globalCallout : undefined,
        error: cmsResponse.error,
        loading: cmsResponse.loading
    };
};

export const resetData = (): void => {
    homeScreenCMSData = null;
};
