import {ApolloError, useQuery} from '@apollo/client';
import getConfig from 'next/config';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';

import {GetBasicCustomerInformation_carts} from 'autogen/GetBasicCustomerInformation';
import {getBasicCustomerInformation} from 'client/graphql/queries/cart-queries';

import {
    GetBasicCustomerInformation,
    GetBasicCustomerInformationVariables
} from '../../autogen/GetBasicCustomerInformation';
import {getCustomerData} from '../graphql/queries/customer-and-feature-queries';
import {useUserDetails} from '../context/user-details';
import {GetCustomerData, GetCustomerDataVariables} from '../../autogen/GetCustomerData';

export const useCustomerUuid = (): string => useSelector((state: any) => state.customer.customerUuid);

export const useCustomerId = (): number | null => useSelector((state: any) => state.customer.customerId);

export const useIsAuthenticated = (): boolean => useSelector((state: any) => state.isAuthenticated);

export const useCustomer = (): any => useSelector((state: any) => state.customer);

export interface CustomerData {
    userName: string;
    activeCartId: number;
    activeCart: GetBasicCustomerInformation_carts | null;
    customerId: number;
    customerUuid: string;
    fuelSaverCardUuid?: string;
    pickupLocationHasLocker: boolean;
    storeId: number;
}

// Documentation: https://github.hy-vee.cloud/web-and-mobile/aisles-online/wiki/Hooks#usecustomerdata
export const useCustomerData = (includeProductCardData?: boolean): CustomerData => {
    const {customerId, customerUuid, userName} = useCustomer();

    const {data} = useQuery<GetBasicCustomerInformation, GetBasicCustomerInformationVariables>(
        getBasicCustomerInformation,
        {
            fetchPolicy: 'cache-first',
            skip: !customerId,
            variables: {
                customerId: Number(customerId),
                customerUuid: customerUuid?.toLowerCase(),
                includeProductCardData: Boolean(includeProductCardData)
            }
        }
    );

    const activeCart = data?.carts ? data.carts[0] : null;

    return {
        activeCart,
        activeCartId: activeCart ? Number(activeCart.cartId) : 0,
        customerId,
        customerUuid,
        fuelSaverCardUuid: data?.customer?.fuelSaverCard?.fuelSaverCardUuid,
        pickupLocationHasLocker: activeCart?.pickupLocation?.pickupLocationHasLocker || false,
        storeId: activeCart?.storeId ? Number(activeCart.storeId) : getConfig().publicRuntimeConfig.defaultStoreId,
        userName
    };
};

export interface IInitialCustomerState {
    data?: GetCustomerData;
    error?: ApolloError | Error;
    loading: boolean;
}

export const useInitialCustomerState = (): IInitialCustomerState => {
    const {loading: userDetailsLoading, customerUuid, error: userDetailsError} = useUserDetails();
    const {
        data,
        loading: queryLoading,
        error: queryError
    } = useQuery<GetCustomerData, GetCustomerDataVariables>(getCustomerData, {
        skip: userDetailsLoading || customerUuid === undefined,
        variables: {
            customerUuid: customerUuid!
        }
    });
    const loading = useMemo(() => userDetailsLoading || queryLoading, [userDetailsLoading, queryLoading]);
    const error: ApolloError | Error | undefined = useMemo(
        () => userDetailsError || queryError,
        [userDetailsError, queryError]
    );

    return {
        data,
        error,
        loading
    };
};
