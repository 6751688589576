import {
    cartForCacheIdentify,
    cartItemForCacheIdentify
} from 'client/graphql/mutations/mutation-helpers/cache-identify-helpers';
import {getActiveCartForGlobalNavigation} from 'client/graphql/queries/cart-queries';
import {cartItemDeleteCartLookupFragment} from 'client/graphql/queries/cart-item-queries';
import {ICartItem} from 'autogen/ICartItem';

import {graphqlClient} from '../../graphql-client';

export const updateCartCacheAfterCartItemCreate = (createdCartItem: ICartItem, customerId: number): void => {
    const client = graphqlClient();
    const cartQueryData = client.readQuery({
        query: getActiveCartForGlobalNavigation,
        variables: {
            customerId
        }
    });

    if (cartQueryData?.carts) {
        const {
            carts: [cart, ...others],
            ...rest
        } = cartQueryData;

        const updatedCartData = {
            ...rest,
            carts: [
                {
                    ...cart,
                    cartItems: [...cart.cartItems, createdCartItem]
                },
                ...others
            ]
        };

        client.writeQuery({
            data: updatedCartData,
            query: getActiveCartForGlobalNavigation,
            variables: {
                customerId
            }
        });
    }
};

export const updateCartCacheAfterCartItemDelete = (cartItemId: string): void => {
    const client = graphqlClient();
    const cartItemCacheId = client.cache.identify(cartItemForCacheIdentify(cartItemId));

    const cachedCartItem = client.readFragment({
        fragment: cartItemDeleteCartLookupFragment,
        id: cartItemCacheId
    });

    if (cachedCartItem) {
        const cachedCartId = client.cache.identify(cartForCacheIdentify(cachedCartItem.cartId));

        client.cache.modify({
            fields: {
                cartItems: (existingCartItemRefs, {readField}) =>
                    existingCartItemRefs.filter((cartItemRef) => readField('cartItemId', cartItemRef) !== cartItemId)
            },
            id: cachedCartId
        });

        client.cache.evict({id: cartItemCacheId});
        client.cache.gc();
    }
};
