import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useQuery} from '@apollo/client';
import Router, {useRouter} from 'next/router';
import {
    MutationActionTypes,
    GlobalNavigationReduxAction,
    IGlobalNavigationStoreConfig
} from '@hy-vee/global-navigation';
import {getNearestStoreFromCookie, ILocation} from '@hy-vee/user-location';

import {RESERVATION_STEPPER} from 'client/enums/modal-types';
import {IStore} from 'autogen/IStore';
import {LocationEcommerceStatus} from 'autogen/globalTypes';
import {
    getActiveCartForGlobalNavigation,
    getActiveCartForGlobalNavigation_carts
} from 'autogen/getActiveCartForGlobalNavigation';
import {
    ECOM_GEO_LOCATION_WEB,
    PRODUCT_DETAILS_GEOLOCATION,
    RESERVATION_DRAWER
} from 'client/enums/feature-toggle-names';

import {setReservationStepperIndex, toggleActiveModal} from '../action-creators';
import {FULFILLMENT} from '../enums/reservation-stepper-enums';
import {useFeatureToggle} from '../context/feature-toggle';
import {FeatureEnabled} from '../context/feature-toggle/types';
import {updateCartCacheAfterCartItemDelete} from '../graphql/mutations/mutation-helpers/cart-mutation-helpers';
import {updateCartItemCacheAfterQuantityChanged} from '../graphql/mutations/mutation-helpers/cart-item-mutation-helpers';
import {useUserDetails} from '../context/user-details';
import {getActiveCartForGlobalNavigation as getActiveCartForGlobalNavigationQuery} from '../graphql/queries/cart-queries';

import {locationIsIneligible} from './view-helpers/fulfillment-view-helpers';
import {getEcommereceStatus} from './cart-helpers';
import {useDebouncedValue} from './view-helpers/debounce-helper';
import {getRelativeLoginUrlWithRedirect} from './redirect-to-login';

interface IGlobalNavigationHeaderCartHook {
    data: getActiveCartForGlobalNavigation_carts | undefined;
    error: Error | undefined;
    loading: boolean;
}

const onStoreChange = (
    dispatch: (any) => void,
    ecommerceStatus: LocationEcommerceStatus,
    featureEnabled: FeatureEnabled,
    customerId?: number
): void => {
    const shouldHideTimeWindow = locationIsIneligible(ecommerceStatus, featureEnabled);

    if (shouldHideTimeWindow) {
        dispatch(setReservationStepperIndex(FULFILLMENT));
    }

    if (!customerId) {
        Router.replace(getRelativeLoginUrlWithRedirect(window.location.pathname));
    } else {
        dispatch(toggleActiveModal(RESERVATION_STEPPER));
    }
};

const onStoreChangeV2 = (
    setIsReservationDrawerOpen: (isOpen: boolean) => void,
    customerId?: number,
    isGeoLocationEnabled?: boolean
): void => {
    if (!customerId && isGeoLocationEnabled) {
        setIsReservationDrawerOpen(true);
    }

    if (!customerId && !isGeoLocationEnabled) {
        Router.replace(getRelativeLoginUrlWithRedirect(window.location.pathname));
    }

    setIsReservationDrawerOpen(true);
};

const storeName = (pickupLocation, cartStore) =>
    cartStore?.name && cartStore?.state ? `${pickupLocation?.name ?? cartStore.name}, ${cartStore.state}` : '';

const getLocationText = (
    cart: getActiveCartForGlobalNavigation_carts | undefined,
    unauthenticatedStore: ILocation | IStore | undefined
): string => {
    if (cart) {
        const {fulfillmentType, pickupLocation, deliveryAddress, store: cartStore} = cart;

        if (fulfillmentType === 'PICKUP') {
            return storeName(pickupLocation, cartStore);
        } else if (fulfillmentType === 'PICKUP_POINT') {
            return storeName(pickupLocation, cartStore);
        } else if (fulfillmentType === 'DELIVERY') {
            // eslint-disable-next-line unicorn/no-lonely-if
            if (deliveryAddress) {
                return `${deliveryAddress.firstName} - ${deliveryAddress.city}, ${deliveryAddress.state}`;
            }
        }

        return '';
    }

    return storeName(null, unauthenticatedStore);
};

const getCartItemsOrderAndQuantityEnumeration = (cart: getActiveCartForGlobalNavigation_carts | undefined): string =>
    [...(cart?.cartItems ?? [])]
        .sort((itemOne, itemTwo) => (itemOne.cartItemId as any) - (itemTwo.cartItemId as any))
        .reduce((accum, item) => `${accum}${item.cartItemId}:${item.quantity},`, '');

export const useGlobalNavigationStoreConfig = (
    store: IStore | undefined,
    cart: getActiveCartForGlobalNavigation_carts | undefined,
    setIsReservationDrawerOpen: (isOpen: boolean) => void
): IGlobalNavigationStoreConfig | undefined => {
    const dispatch = useDispatch();
    const {featureEnabled} = useFeatureToggle();
    const ecommerceStatus = getEcommereceStatus(cart);
    const {customerId} = useUserDetails();
    const router = useRouter();
    const isProductDetailsPage = router.pathname.includes('p/[...id]');
    const isReservationDrawerEnabled = featureEnabled(RESERVATION_DRAWER);
    const isGeoLocationEnabled = featureEnabled(ECOM_GEO_LOCATION_WEB);
    const isProductDetailsGeolocationEnabled = featureEnabled(PRODUCT_DETAILS_GEOLOCATION) && isProductDetailsPage; // we have 2 layers of features for geolocations, this one exposes it just for product details page with the intent of showing it for beta testing in prod
    const nearestStoreInCookie = getNearestStoreFromCookie();
    const [currentStore, setCurrentStore] = useState<ILocation | IStore | undefined>(undefined);
    const storeInitialized = useRef(false);

    useEffect(() => {
        // use a ref to prevent unecessary rerenders after store is set
        if (storeInitialized.current && currentStore) {
            return;
        }

        if (store) {
            setCurrentStore(store);
        } else if (
            nearestStoreInCookie &&
            !customerId &&
            (isGeoLocationEnabled || isProductDetailsGeolocationEnabled)
        ) {
            setCurrentStore(nearestStoreInCookie);
        }

        storeInitialized.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, nearestStoreInCookie, isGeoLocationEnabled, isProductDetailsGeolocationEnabled]);

    const cartConfig = cart
        ? {
              fulfillmentExpiration: cart.fulfillmentExpiration,
              fulfillmentTime: {
                  windowEnd: cart.fulfillmentWindowEnd,
                  windowStart: cart.fulfillmentWindowStart
              },
              fulfillmentType: cart.fulfillmentType
          }
        : undefined;

    return {
        locationText: getLocationText(cart, currentStore),
        onStoreChange: () =>
            isReservationDrawerEnabled
                ? onStoreChangeV2(
                      setIsReservationDrawerOpen,
                      customerId,
                      isGeoLocationEnabled || isProductDetailsGeolocationEnabled
                  )
                : onStoreChange(dispatch, ecommerceStatus, featureEnabled, customerId),
        ...cartConfig
    };
};

export const useGlobalNavigationHeaderCart = (): IGlobalNavigationHeaderCartHook => {
    const {customerId, error: userDetailsError, loading: userDetailsLoading} = useUserDetails();
    const {
        data: cartData,
        error: cartDataError,
        loading: cartDataLoading
    } = useQuery<getActiveCartForGlobalNavigation>(getActiveCartForGlobalNavigationQuery, {
        errorPolicy: 'all',
        skip: Boolean(userDetailsLoading || userDetailsError) || !customerId,
        variables: {
            customerId
        }
    });
    const cart = cartData?.carts?.[0];
    const cartItemEnumeration = getCartItemsOrderAndQuantityEnumeration(cart);
    const [, cartItemEnumerationBouncing] = useDebouncedValue(cartItemEnumeration);

    return {
        data: cart,
        error: userDetailsError || cartDataError,
        loading: cartItemEnumerationBouncing || cartDataLoading || userDetailsLoading
    };
};

export const handleGlobalNavigationDataChange = (action: GlobalNavigationReduxAction): void => {
    const {type: actionType, ...data} = action;

    switch (actionType) {
        case MutationActionTypes.changeCartItemQuantity:
            return updateCartItemCacheAfterQuantityChanged(data.id, data.quantity);
        case MutationActionTypes.removeCartItem:
            return updateCartCacheAfterCartItemDelete(data.id);
        default:
            return undefined;
    }
};
