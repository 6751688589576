import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import VerifyAddress from '@hy-vee/web-address-form/lib/components/verify-address';

import {
    clearAddressSuggestions,
    postNewCustomerDeliveryAddress,
    clearSaveAddressErrorStatus
} from '../../../../action-creators';
import {getNewAddressPayloadForSaving} from '../../../../utils/view-helpers/address-view-helpers';

const VerifyNewAddress = ({actions, addressSuggestions, enteredDeliveryAddress}) => {
    const navigateBack = () => {
        actions.clearSaveAddressErrorStatus();
        actions.clearAddressSuggestions();
    };

    const saveCustomerAddressIfSelected = (address) => {
        actions.clearSaveAddressErrorStatus();
        actions.clearAddressSuggestions();

        const newAddress = getNewAddressPayloadForSaving({
            ...enteredDeliveryAddress,
            ...address
        });

        actions.postNewCustomerDeliveryAddress(newAddress);
    };

    return (
        <VerifyAddress
            enteredAddress={enteredDeliveryAddress}
            name={`${enteredDeliveryAddress.firstName} ${enteredDeliveryAddress.lastName}`}
            onNavigateBack={navigateBack}
            onSelect={saveCustomerAddressIfSelected}
            verifiedAddresses={addressSuggestions.addresses || []}
        />
    );
};

const mapStateToProps = (state) => ({
    addressSuggestions: state.addressSuggestions,
    enteredDeliveryAddress: state.storeSelection.enteredDeliveryAddress
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearAddressSuggestions,
            clearSaveAddressErrorStatus,
            postNewCustomerDeliveryAddress
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyNewAddress);
