import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {doStateAndZipCodeMatchVerifiedData} from '@hy-vee/shared-utils-aisles-online';
import AddressForm from '@hy-vee/web-address-form';
import {Button, SecondaryButton} from '@hy-vee/web-core';

import {
    clearAddressSuggestions,
    clearSaveAddressErrorStatus,
    loadAddressSuggestionsForAddress,
    setEnteredDeliveryAddress,
    postNewCustomerDeliveryAddress,
    selectMtoDeliveryAddress,
    setAddressErrorStatus,
    setShouldDisplayNewAddressForm,
    setStoreSelectionTypeTab
} from 'client/action-creators';
import {SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST} from 'client/action-types';
import {LEGACY_VERIFY_LEVELS} from 'client/enums/address-types';
import {DEFAULT_VIEW, DELIVERY_TAB, DELIVERY_FULFILLMENT_SELECTION} from 'client/enums/store-selection-tab-types';
import {assignParentUrl} from 'client/utils/iframe-helpers';
import {
    getAddressPayloadForSuggestions,
    getAddressString,
    getNewAddressPayloadForSaving
} from 'client/utils/view-helpers/address-view-helpers';
import {getFulfillmentChangePageUrl} from 'client/utils/view-helpers/fulfillment-view-helpers';
import {isLoading} from 'client/utils/view-helpers/loading-indicator-view-helpers';
import {reconcileUspsAndSuggestedAddress} from 'client/utils/view-helpers/reconcile-usps-and-suggested-address';
import DisableSectionLoader from 'client/views/components/disable-section-loader';
import DisplayContentContainer from 'client/views/components/display-content-container';

import VerifyNewAddress from './verify-new-address';

const {NONE, VERIFIED} = LEGACY_VERIFY_LEVELS;

/**
 * @typedef {typeof DEFAULT_VIEW | typeof DELIVERY_FULFILLMENT_SELECTION | typeof DELIVERY_TAB} SetStoreSelectionTypeTabTypes

 * @typedef {(tab: SetStoreSelectionTypeTabTypes) => void} SetStoreSelectionTypeTabAction
 * @type {function}
 
 * @typedef NewDeliveryAddressFormActions
 * @type {object}
 * @property {() => void} clearAddressSuggestions
 * @property {() => void} clearSaveAddressErrorStatus
 * @property {(addressData: IAddress) => object} loadAddressSuggestionsForAddress
 * @property {(address: INewDeliveryAddressPayload) => Dispatch<object>} postNewCustomerDeliveryAddress
 * @property {(deliveryAddress: IDeliveryAddress) => Dispatch<object>} selectMtoDeliveryAddress
 * @property {(error: string) => Dispatch<object>} setAddressErrorStatus
 * @property {(enteredDeliveryAddress: INewDeliveryAddressDerivedFromAddressFormData) => void} setEnteredDeliveryAddress
 * @property {(condition: boolean) => void} setShouldDisplayNewAddressForm
 * @property {SetStoreSelectionTypeTabAction} setStoreSelectionTypeTab

 * @typedef LoadingIndicator
 * @type {object}
 * @property {string} name
 * @property {number} requestsCompleted
 * @property {number} requestsStarted

 * @typedef NewDeliveryAddressFormProps
 * @type {object}
 * @property {NewDeliveryAddressFormActions} actions
 * @property {IAddressSuggestions} addressSuggestions
 * @property {IDeliveryAddress} deliveryAddress
 * @property {IDeliveryAddress[]} deliveryAddresses
 * @property {INewDeliveryAddressDerivedFromAddressFormData} enteredDeliveryAddress
 * @property {boolean} isMto
 * @property {LoadingIndicator[]} loadingIndicators
 * @property {boolean} shouldDisplayNewAddressForm
 * @property {(deliveryAddress: IDeliveryAddress, fulfillmentLocationId: string) => void} onSelectDeliveryLocation
 * @property {Dispatch<SetStateAction<boolean>>} setShowStateZipMismatchError
*/

/**
 * @typedef {NewDeliveryAddressFormProps} props
 * @extends {Component<props>}
 */
class NewDeliveryAddressForm extends Component {
    componentDidMount() {
        this.props.actions.clearAddressSuggestions();
    }

    componentDidUpdate(prevProps) {
        const {
            handleNewAddress,
            isAddressSuggestionWithNoneVerifyLevelValidAddress,
            props: {
                actions,
                addressSuggestions,
                deliveryAddress,
                enteredDeliveryAddress,
                isMto,
                setShowStateZipMismatchError
            }
        } = this;

        if (prevProps.deliveryAddress !== deliveryAddress) {
            if (isMto) {
                actions.selectMtoDeliveryAddress({
                    address: getAddressString(deliveryAddress),
                    deliveryAddressId: deliveryAddress.deliveryAddressId
                });
            } else if (deliveryAddress) {
                handleNewAddress();
            }
        } else if (addressSuggestions?.verifyLevel === VERIFIED) {
            actions.clearAddressSuggestions();

            const addressPayload = getNewAddressPayloadForSaving({
                ...enteredDeliveryAddress,
                ...addressSuggestions.addresses[0]
            });

            actions.postNewCustomerDeliveryAddress(addressPayload);
        } else if (addressSuggestions?.verifyLevel === NONE && !isAddressSuggestionWithNoneVerifyLevelValidAddress()) {
            setShowStateZipMismatchError(true);
        }
    }

    componentWillUnmount() {
        this.cleanup();
    }

    cleanup = () => {
        const {actions, deliveryAddresses} = this.props;

        actions.setShouldDisplayNewAddressForm(!deliveryAddresses.length);
    };

    clearErrors = () => {
        const {actions, setShowStateZipMismatchError} = this.props;

        actions.clearSaveAddressErrorStatus();
        setShowStateZipMismatchError(false);
    };

    handleNewAddress = () => {
        const {actions, deliveryAddress, onSelectDeliveryLocation} = this.props;
        const {fulfillmentLocations = null, deliveryAddressId} = deliveryAddress;

        // `fulfillmentLocations` can be `null`, so we check to make sure
        // it has a length property, otherwise the `if` and `else` logic
        // could fail
        if (fulfillmentLocations?.length) {
            if (fulfillmentLocations.length > 1) {
                actions.setStoreSelectionTypeTab(DELIVERY_FULFILLMENT_SELECTION);
            } else if (onSelectDeliveryLocation) {
                actions.setStoreSelectionTypeTab(DELIVERY_TAB);
                onSelectDeliveryLocation(deliveryAddress, fulfillmentLocations[0].fulfillmentLocationId);
            } else {
                const basketContinuityUrl = getFulfillmentChangePageUrl(
                    fulfillmentLocations[0].fulfillmentLocationId,
                    deliveryAddressId
                );

                assignParentUrl(basketContinuityUrl);
            }
        } else {
            console.error('This address does not have any fulfillment locations.');
            actions.setAddressErrorStatus("We're sorry, it looks like we don't have delivery in your area.");
        }
    };

    isAddressSuggestionWithNoneVerifyLevelValidAddress = () => {
        const {addressSuggestions, enteredDeliveryAddress} = this.props;
        const doExistSuggestedAddressesOrUspsAddress = Boolean(
            addressSuggestions?.addresses?.length || addressSuggestions?.uspsAddress
        );

        if (doExistSuggestedAddressesOrUspsAddress) {
            const {addresses: suggestedAddresses, uspsAddress} = addressSuggestions;
            const reconciledAddress = reconcileUspsAndSuggestedAddress(uspsAddress, suggestedAddresses?.[0]);

            return doStateAndZipCodeMatchVerifiedData(enteredDeliveryAddress, reconciledAddress);
        }

        return false;
    };

    onSubmit = (values) => {
        const {
            clearErrors,
            props: {actions}
        } = this;
        const {addressOne, addressTwo, state} = values;

        actions.clearAddressSuggestions();
        clearErrors();

        actions.setEnteredDeliveryAddress({
            ...values,
            addressOne: addressOne.trim(),
            addressTwo: addressTwo?.trim() || null,
            state: state.value
        });
        actions.loadAddressSuggestionsForAddress(getAddressPayloadForSuggestions(values));
    };

    onCancel = () => {
        const {
            clearErrors,
            props: {actions, deliveryAddresses, isMto, shouldDisplayNewAddressForm}
        } = this;
        const tab = isMto || (deliveryAddresses.length && shouldDisplayNewAddressForm) ? DELIVERY_TAB : DEFAULT_VIEW;

        clearErrors();
        actions.setShouldDisplayNewAddressForm(!deliveryAddresses.length);
        actions.setStoreSelectionTypeTab(tab);
    };

    shouldDisableSection = () => isLoading(this.props.loadingIndicators, SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST);

    shouldDisplayAddressSuggestionsForm = () => {
        const {
            isAddressSuggestionWithNoneVerifyLevelValidAddress,
            props: {addressSuggestions}
        } = this;

        return addressSuggestions?.verifyLevel === NONE ? isAddressSuggestionWithNoneVerifyLevelValidAddress() : false;
    };

    render() {
        const {onCancel, onSubmit, shouldDisableSection, shouldDisplayAddressSuggestionsForm} = this;

        return (
            <DisableSectionLoader isLoading={shouldDisableSection()}>
                <DisplayContentContainer shouldDisplayContent={shouldDisplayAddressSuggestionsForm()}>
                    <VerifyNewAddress />
                </DisplayContentContainer>
                <DisplayContentContainer shouldDisplayContent={!shouldDisplayAddressSuggestionsForm()}>
                    <AddressForm
                        PrimaryButton={(props) => (
                            <Button data-testid="address-form-submit-button" {...props}>
                                {'Save & Continue'}
                            </Button>
                        )}
                        SecondaryButton={() => <SecondaryButton onClick={onCancel}>{'Go Back'}</SecondaryButton>}
                        onSubmit={onSubmit}
                    />
                </DisplayContentContainer>
            </DisableSectionLoader>
        );
    }
}

const mapStateToProps = (state) => ({
    addressSuggestions: state.addressSuggestions,
    deliveryAddress: state.deliveryAddress,
    deliveryAddresses: state.storeSelection.deliveryAddresses,
    enteredDeliveryAddress: state.storeSelection.enteredDeliveryAddress,
    isMto: state.storeSelection.isMto,
    loadingIndicators: state.loadingIndicators,
    shouldDisplayNewAddressForm: state.storeSelection.shouldDisplayNewAddressForm
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearAddressSuggestions,
            clearSaveAddressErrorStatus,
            loadAddressSuggestionsForAddress,
            postNewCustomerDeliveryAddress,
            selectMtoDeliveryAddress,
            setAddressErrorStatus,
            setEnteredDeliveryAddress,
            setShouldDisplayNewAddressForm,
            setStoreSelectionTypeTab
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDeliveryAddressForm);
