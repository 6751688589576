import {gql} from '@apollo/client';

export const deleteCartItemMutation = gql`
    mutation DeleteCartItemMutation($customerId: Int!, $cartItemId: Int!) {
        deleteCartItem(customerId: $customerId, cartItemId: $cartItemId)
    }
`;

export const updateCartItemMutation = gql`
    mutation UpdateCartItemMutation($customerId: Int!, $cartItemInput: updateCartItemInput!) {
        updateCartItem(customerId: $customerId, cartItemInput: $cartItemInput) {
            cartItemId
            note
            quantity
            tax
        }
    }
`;

export const bulkUpdateCartItemsMutation = gql`
    mutation BulkUpdateCartItemsMutation($customerId: Int!, $bulkUpdateCartItemsInput: [updateCartItemInput!]) {
        bulkUpdateCartItems(customerId: $customerId, bulkUpdateCartItemsInput: $bulkUpdateCartItemsInput) {
            cartItemId
            note
            quantity
            tax
        }
    }
`;

export const updateCartSubstitutionPreferenceMutation = gql`
    mutation updateCartSubstitutionPreferenceMutation($updateCartInput: updateCartInput!) {
        updateCart(updateCartInput: $updateCartInput) {
            cartId
            substitutionPreference
        }
    }
`;

export const updateCartMutation = gql`
    mutation updateCartMutation($updateCartInput: updateCartInput!) {
        updateCart(updateCartInput: $updateCartInput) {
            cartId
        }
    }
`;

export const createCartMutation = gql`
    mutation CreateCart($createCartInput: createCartInput!) {
        createCart(cartInput: $createCartInput) {
            cartId
        }
    }
`;
export const validateCartForRetailItemIds = gql`
    mutation CartValidation {
        addRetailItemIdsToActiveCartItems {
            cartItems {
                cartItemId
                quantity
                product {
                    productId
                    averageWeight
                }
                storeProduct {
                    storeProductId
                    isWeighted
                    price
                    priceMultiple
                }
            }
            invalidCartItems {
                cartItemId
                product {
                    productId
                    productImages {
                        uri
                        size
                        type
                        isPrimary
                    }
                    name
                }
            }
        }
    }
`;

export const updateCartGroceryOptionsMutation = gql`
    mutation updateCartGroceryOptionsMutation($updateCartInput: updateCartInput!) {
        updateCart(updateCartInput: $updateCartInput) {
            cartId
            canBuyAgeRestrictedItems
            leaveUnattended
            bagPreferenceType
            instructions
            smsNotificationPhoneNumber
            requestsPickupLocationToBeWheelchairAccessible
        }
    }
`;
