import {IAddress} from 'client/types/addresses';

export const reconcileUspsAndSuggestedAddress = (uspsAddress?: IAddress, postalAddress?: IAddress): IAddress => {
    let reconciledAddress = {} as IAddress;

    if (uspsAddress) {
        if (postalAddress) {
            reconciledAddress = {
                ...postalAddress,
                city: postalAddress.city.toUpperCase() !== uspsAddress.city ? uspsAddress.city : postalAddress.city,
                state: postalAddress.state !== uspsAddress.state ? uspsAddress.state : postalAddress.state,
                zip: postalAddress.zip !== uspsAddress.zip ? uspsAddress.zip : postalAddress.zip
            };
        } else {
            reconciledAddress = {...uspsAddress};
        }
    }

    return reconciledAddress;
};
