/**
 * Transforms address validation data into a structured format suitable for address suggestions.
 *
 * This function takes in the result of an address validation service and returns a response
 * containing a list of addresses and a verification level. The function processes the data to
 * determine if the address is validated and constructs an address suggestion based on the validation result.
 *
 * @param {Awaited<ReturnType<typeof addressValidator>>} data - The result data from the address validator service.
 *
 * @returns {AddressValidationResponse} An object containing the list of address suggestions and the verification level.
 *
 * The returned object includes:
 * - `addresses`: An array of address objects, each containing:
 *   - `addressOne` (string): The primary address line.
 *   - `addressTwo` (string, optional): The secondary address line.
 *   - `city` (string): The city of the address.
 *   - `state` (string): The state of the address.
 *   - `zip` (string): The ZIP code of the address.
 * - `verifyLevel` (LEGACY_VERIFY_LEVELS): The verification level of the address, either 'NONE' or 'VERIFIED'.
 *
 * Example usage:
 * ```
 * const validationResult = await addressValidator(someAddressData);
 * const addressSuggestions = transformAddressValidationToAddressSuggestions(validationResult.result);
 * ```
 */

import {LEGACY_VERIFY_LEVELS} from 'client/enums/address-types';
import {IAddress} from 'client/types/addresses';

interface UspsAddress {
    firstAddressLine: string;
    secondAddressLine: string;
    city: string;
    state: string;
    zipCode: string;
    cityStateZipAddressLine: string; // format: "CITY STATE 00000"
}

interface AddressValidationResponse {
    addresses: IAddress[];
    uspsAddress: IAddress;
    verifyLevel: LEGACY_VERIFY_LEVELS;
}

// The Google Maps Address Validation API sometimes returns city names from the USPS
// API that are abbreviated instead of completely spelled out. While "West Des Moines"
// was found in testing, it could very well be the case that cities like East Los Angeles
// or West Palm Beach are abbreviated ELA and WPB, respectively
const formatUspsAddressCity = (uspsCity: string): string => (uspsCity === 'WDM' ? 'WEST DES MOINES' : uspsCity);

const formatUspsAddressZipCode = (uspsAddress: UspsAddress) => {
    let formattedUspsAddressZipCode = uspsAddress.zipCode;

    if (!uspsAddress.zipCode) {
        const splitCityStateZipAddressLine = uspsAddress.cityStateZipAddressLine.split(' ');

        if (splitCityStateZipAddressLine.length === 3) {
            const zipCodeWithExtension = splitCityStateZipAddressLine[2];
            const zipCodeWithoutExtension = zipCodeWithExtension.slice(0, 5);

            formattedUspsAddressZipCode = zipCodeWithoutExtension;
        }
    }

    return formattedUspsAddressZipCode;
};

const transformAddressValidationToAddressSuggestions = (data?: Awaited<ReturnType<any>>): AddressValidationResponse => {
    // Build Address
    let addresses: AddressValidationResponse['addresses'] = [];
    let verifiedUspsAddress: AddressValidationResponse['uspsAddress'] = {} as IAddress;
    let verifyLevel: AddressValidationResponse['verifyLevel'] = LEGACY_VERIFY_LEVELS.NONE;

    const postalAddress = data?.rawResponse?.result?.address?.postalAddress || {};
    const uspsAddress = data?.rawResponse?.result?.uspsData?.standardizedAddress;

    if (
        data?.verdict &&
        postalAddress?.addressLines &&
        postalAddress?.addressLines[0] &&
        postalAddress?.locality &&
        postalAddress?.administrativeArea &&
        postalAddress?.postalCode
    ) {
        const {verdict} = data;

        // If User Input Is Valid
        if (verdict.userInputValidationStatus) {
            verifyLevel = LEGACY_VERIFY_LEVELS.VERIFIED;
        }

        // Build Suggested Addresses
        if (verdict.returnedAddressValidationStatus) {
            addresses = [
                {
                    addressOne: postalAddress.addressLines[0],
                    addressTwo: postalAddress.addressLines?.[1],
                    city: postalAddress.locality,
                    state: postalAddress.administrativeArea,
                    zip: postalAddress.postalCode
                }
            ];
        }

        // The USPS Standardized Address is always an corrected address, so we use this
        // to compare against what the user input to ensure the address is valid, especially
        // when it comes to State & Zip Code matching
        if (uspsAddress?.firstAddressLine && uspsAddress?.city && uspsAddress?.state && uspsAddress?.zipCode) {
            verifiedUspsAddress = {
                addressOne: uspsAddress.firstAddressLine,
                addressTwo: uspsAddress.secondAddressLine,
                city: formatUspsAddressCity(uspsAddress.city),
                state: uspsAddress.state,
                zip: formatUspsAddressZipCode(uspsAddress)
            };
        }
    }

    // Found Verified Address Suggestion
    return {
        addresses,
        uspsAddress: verifiedUspsAddress,
        verifyLevel
    };
};

export default transformAddressValidationToAddressSuggestions;
