import {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {addPropsToChildren} from '../../utils/view-helpers/view-helpers';

import AlertMessageBanner from './alert-message-banner';
import ReloadDataButton from './reload-data-button';

const StyledErrorBoundary = styled.div`
    min-height: 300px;
    padding-top: 50px;
`;

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false
        };
    }

    componentDidCatch(error) {
        this.setState({
            error
        });

        console.error('Error caught in error-boundary.js', {
            error
        });
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {children, customer, ...props} = this.props;

        if (this.state.error) {
            return (
                <StyledErrorBoundary>
                    <AlertMessageBanner pageContext>
                        <span>
                            {'An error has occurred.'}
                            <ReloadDataButton />
                        </span>
                    </AlertMessageBanner>
                </StyledErrorBoundary>
            );
        }

        return addPropsToChildren(children, props);
    }
}

const mapStateToProps = (state) => ({
    customer: state.customer
});

export default connect(mapStateToProps)(ErrorBoundary);
