/*
 * Newer @hy-vee/web-store-select needs this due to some babeling that occurs in newer dependencies.
 */
import 'regenerator-runtime/runtime';
import '@hy-vee/design-tokens/src/tokens.css';
import '@hy-vee/design-system/lib/design-system.css';
import '@hy-vee/global-navigation/lib/cjs/index.css';
import '@hy-vee/react-web-and-mobile-ui-components/whammy.css';

import type {ReactElement, ReactNode} from 'react';
import type {NextPage} from 'next';
import {AppProps as NextAppProps} from 'next/app';
import {GlobalStyle} from '@hy-vee/web-core/lib/global';
import {useCallback, useEffect} from 'react';
import Router, {useRouter} from 'next/router';
import {hyveeRedOutline} from '@hy-vee/themes';
import {initialize} from '@hy-vee/monitoring';
import {ThemeProvider} from 'styled-components';
import {DefaultSeo} from 'next-seo';
import {ApolloProvider} from '@apollo/client';
import {GlobalNavigationProvider, IGlobalNavigationClientPaths} from '@hy-vee/global-navigation';
import {SnackbarProvider, SnackbarProviderProps} from 'notistack';

import {useApollo} from 'client/graphql/use-apollo';
import {AislesOnlineGlobalStyle} from 'client/styles/global-style';
import {getGlobalLocationPathnameWithSearch} from 'client/lib/env';
import {reportPageView} from 'client/services/analytics-service';
import {AISLES_ONLINE_PAGE_TITLE} from 'client/enums/page-title';
import UserDetailsProvider from 'client/context/user-details/provider';
import FeatureToggleProvider from 'client/context/feature-toggle/provider';
import {GoogleTagManagerScript} from 'client/lib/gtm';
import {RiskifiedBeaconScript, notifyRiskifiedOfRouteChange} from 'client/lib/RiskifiedBeacon';
import {ReservationDrawerProvider} from 'client/hooks/use-reservation-drawer';

import '../sass/index.scss';
import '../sass/_aisles-online-reset.scss';

// @TYPES
export type PageLayout = (page: ReactElement) => ReactNode;
export type Page<P = {}, IP = P> = NextPage<P, IP> & {
    layout?: PageLayout;
};

type AppProps = NextAppProps & {
    Component: Page;
};

// GLOBALS
Router.events.on('routeChangeComplete', reportPageView);

const globalNavigationClientPaths: IGlobalNavigationClientPaths = {
    apiPrefix: '/aisles-online/api',
    loginPath: '/aisles-online/login',
    logoutPath: '/aisles-online/logout'
};

// _APP COMPONENT
const AislesOnlineApp = ({Component, pageProps}: AppProps) => {
    const apolloClient = useApollo(pageProps);
    const pageDefaultSEO = {
        description:
            'Easily order groceries online for curbside pickup or delivery.' +
            ' Pickup is always free with a minimum $24.95 purchase. Aisles Online has' +
            ' thousands of low-price items to choose from, so you can shop your list' +
            ' without ever leaving the house.',
        openGraph: {
            site_name: 'Hy-Vee'
        },
        title: AISLES_ONLINE_PAGE_TITLE
    };

    const snackbarConfig: SnackbarProviderProps = {
        anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
        },
        autoHideDuration: 3000,
        classes: {
            containerAnchorOriginBottomCenter: 'snackbar-container'
        },
        maxSnack: 3
    };

    const storePathValues = useCallback(() => {
        const path = getGlobalLocationPathnameWithSearch(global);

        if (!path) {
            return;
        }

        const previousPath = window.sessionStorage.getItem('currentPath');

        if (previousPath && path !== previousPath) {
            window.sessionStorage.setItem('previousPath', previousPath);
        }

        window.sessionStorage.setItem('currentPath', path);
    }, []);

    initialize();
    const router = useRouter();

    useEffect(() => storePathValues, [router.pathname, storePathValues]);

    // add route change handler for Riskified
    useEffect(() => {
        router.events.on('routeChangeComplete', notifyRiskifiedOfRouteChange);

        return () => {
            router.events.off('routeChangeComplete', notifyRiskifiedOfRouteChange);
        };
    }, [router]);

    storePathValues();

    // Use the layout defined at the page level, if available
    const renderComponentWithLayout = Component.layout ?? ((page) => page);

    return (
        <>
            <GoogleTagManagerScript />
            <DefaultSeo {...pageDefaultSEO} />
            <GlobalStyle />
            <AislesOnlineGlobalStyle />
            <ThemeProvider theme={hyveeRedOutline}>
                <UserDetailsProvider fallback={pageProps.fallback}>
                    <ApolloProvider client={apolloClient}>
                        <FeatureToggleProvider>
                            <RiskifiedBeaconScript />
                            <GlobalNavigationProvider clientPaths={globalNavigationClientPaths}>
                                <ReservationDrawerProvider>
                                    <SnackbarProvider {...snackbarConfig}>
                                        {renderComponentWithLayout(<Component {...pageProps} />)}
                                    </SnackbarProvider>
                                </ReservationDrawerProvider>
                            </GlobalNavigationProvider>
                        </FeatureToggleProvider>
                    </ApolloProvider>
                </UserDetailsProvider>
            </ThemeProvider>
        </>
    );
};

export default AislesOnlineApp;

export {logWebVitals as reportWebVitals} from 'client/utils/log-web-vitals';
