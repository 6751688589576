import {OrderTypes} from '@hy-vee/shared-utils-aisles-online';
import {getDistance, convertDistance} from 'geolib';

import {
    IAddress,
    AddressFormData,
    INewDeliveryAddressDerivedFromAddressFormData,
    INewDeliveryAddressPayload
} from 'client/types/addresses';

const pickLocationNameOrStoreValue = (fulfillmentLocation) => {
    if (fulfillmentLocation?.locationName) {
        return fulfillmentLocation.locationName;
    }

    return fulfillmentLocation?.store ? fulfillmentLocation.store.name : null;
};

export const getAddressObjectFromDeliveryAddress = (deliveryAddress) => {
    const addressTwo = deliveryAddress.addressTwo ? ` ${deliveryAddress.addressTwo}` : '';

    return {
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        streetAddress: `${deliveryAddress.addressOne}${addressTwo}`,
        zip: deliveryAddress.zip
    };
};

export const getAddressObjectFromLocation = (location) => ({
    city: location.city,
    state: location.state,
    streetAddress: location.address,
    zip: location.zip
});

export const getAddressString = (location) => {
    if (location) {
        const {addressOne, addressTwo, city, state, zip} = location;

        return `${addressOne}, ${addressTwo ? `${addressTwo} ` : ''}${city}, ${state} ${zip}`;
    }

    return null;
};

export const getCityStateZip = (address) => `${address.city}, ${address.state} ${address.zip}`;

export const getPickupLocationName = (fulfillmentType, pickupLocation, fulfillmentLocation) => {
    if (fulfillmentType !== OrderTypes.DELIVERY) {
        if (pickupLocation) {
            return pickupLocation.name;
        }

        return pickLocationNameOrStoreValue(fulfillmentLocation);
    }

    return null;
};

export const getPickupLocationsWithMilesAway = <T>(
    pickupLocations: (T & {latitude: string; longitude: String})[],
    latitude: string,
    longitude: string
): (T & {milesAway: number})[] => {
    const distance = (lat, long) =>
        getDistance(
            {
                latitude: lat,
                longitude: long
            },
            {
                latitude,
                longitude
            }
        );

    return pickupLocations.map((currentPickupLocation) => ({
        ...currentPickupLocation,
        milesAway: Number(
            convertDistance(distance(currentPickupLocation.latitude, currentPickupLocation.longitude), 'mi').toFixed(1)
        )
    }));
};

export const getStoreAddressIfNeeded = (store, fulfillmentType) => {
    if (store && fulfillmentType !== OrderTypes.DELIVERY) {
        return getAddressObjectFromLocation(store);
    }

    return null;
};

export const getNameForFulfillment = (deliveryAddress, pickupLocation, store, fulfillmentType) => {
    if (fulfillmentType === OrderTypes.DELIVERY) {
        return `${deliveryAddress?.firstName?.trim()} ${deliveryAddress?.lastName?.trim() || ''}`.trim();
    } else if (fulfillmentType === OrderTypes.PICKUP_POINT || fulfillmentType === OrderTypes.PICKUP_LOCKER) {
        return pickupLocation.name;
    }

    return store.name;
};

export const getAddressForFulfillment = (deliveryAddress, pickupLocation, store, fulfillmentType) => {
    if (deliveryAddress) {
        return getAddressObjectFromDeliveryAddress(deliveryAddress);
    }

    if (pickupLocation) {
        return getAddressObjectFromLocation(pickupLocation);
    }

    return getStoreAddressIfNeeded(store, fulfillmentType);
};

export const getNewAddressPayloadForSaving = (
    values: INewDeliveryAddressDerivedFromAddressFormData
): INewDeliveryAddressPayload => {
    const phoneNumberWithoutDashes = values.phoneNumber.replace(/-/gu, '');
    const phoneNumber = `+1${phoneNumberWithoutDashes.slice(-10)}`;

    return {
        addressOne: values.addressOne.trim(),
        addressTwo: (values.addressTwo && values.addressTwo.trim()) || null,
        city: values.city.trim(),
        companyName: (values.companyName && values.companyName.trim()) || null,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        locationType: 'Residence',
        phoneNumber,
        state: values.state,
        zip: values.zip.slice(0, 5)
    };
};

export const getAddressPayloadForSuggestions = ({
    addressOne,
    addressTwo,
    city,
    state,
    zip
}: AddressFormData): IAddress => ({
    addressOne: addressOne.trim(),
    addressTwo: addressTwo?.trim(),
    city,
    state: state.value,
    zip
});
