import Script from 'next/script';
import {v4 as uuid} from 'uuid';

import {useLocalStorage} from 'client/hooks/use-local-storage';

export const RISKIFIED_LOCAL_STORAGE_KEY = 'riskifiedSessionId';

export const destroyRiskifiedLocalStorage = () => {
    try {
        window.localStorage.removeItem(RISKIFIED_LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error(error);
        console.error('Could not remove Riskified beacon from localStorage', {error});
    }
};

export const RiskifiedBeaconScript = () => {
    useLocalStorage(RISKIFIED_LOCAL_STORAGE_KEY, uuid());

    return (
        <Script
            dangerouslySetInnerHTML={{
                __html: `
                (function() {
                    function riskifiedBeaconLoad() {
                        var store_domain = 'hy-vee.com';
                        var session_id = JSON.parse(window.localStorage.getItem('riskifiedSessionId'));
                        var url = ('https:' == document.location.protocol ? 'https://' : 'http://')
                        + "beacon.riskified.com?shop=" + store_domain + "&sid=" + session_id;
                        var s = document.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = url;
                        var x = document.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                    }
                    if (window.attachEvent)
                        window.attachEvent('onload', riskifiedBeaconLoad)
                    else
                        window.addEventListener('load', riskifiedBeaconLoad, false);
                })();
                `
            }}
            data-testid="riskify-init"
            id="riskify-init"
            strategy="beforeInteractive"
        />
    );
};

/**
 * send route changes to Riskified for tracking user journey
 */
export const notifyRiskifiedOfRouteChange = (url) => {
    // safely access Riskified library
    if (window.RISKX && typeof window.RISKX.go === 'function') {
        window.RISKX.go(url);
    }
};
