import Alert from '@hy-vee/web-core/lib/components/alert/index';

import AlertStatusType from 'client/enums/alert-status-types';
import {GHOST_VARIANT} from 'client/enums/alert-variant-types';

export const StateZipCodeMismatchError = () => (
    <Alert status={AlertStatusType.WARNING_ALERT} variant={GHOST_VARIANT}>
        {
            'The State or Zip Code is invalid for this address, or the combination of State and Zip Code you entered do not match.'
        }
    </Alert>
);
